<template>
	<layout-div>
		<div class="card">
			<form>
				<div class="card-header">
					<button @click="handleSave()"
							:disabled="isSaving"
							type="button"
							class="btn btn-info mt-3 float-left">
						儲存
					</button>

					<router-link class="btn btn-default mt-3 float-right"
								to="/admin/Admin/index">
						返回列表
					</router-link>
				</div>
				<div class="card-body">
					<div class="form-horizontal col-md-8">
						<div class="form-group row">
							<label htmlFor="account" class="col-sm-2 col-form-label">帳號</label>
							<div class="col-sm-10">
								<input v-model="model.account"
									type="text"
									class="form-control"
									id="account"
									name="account"
								required readonly />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="username" class="col-sm-2 col-form-label">員工姓名</label>
							<div class="col-sm-10">
								<input v-model="model.username"
									type="text"
									class="form-control"
									id="username"
									name="username"
								required />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="password" class="col-sm-2 col-form-label">密碼</label>
							<div class="col-sm-10">
								<input v-model="model.password"
									type="password"
									class="form-control"
									id="password"
									name="password"
								/>
							<font color="blue">如果不需改密碼, 請保持空白</font>
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="mail" class="col-sm-2 col-form-label">電子信箱</label>
							<div class="col-sm-10">
								<input v-model="model.mail"
									type="text"
									class="form-control"
									id="mail"
									name="mail"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="role" class="col-sm-2 col-form-label">角色群組</label>
							<div class="col-sm-10">
								<select v-model="model.role" class="form-control" id="role"
											name="role">
											<option value="1">一般員工</option>
											<option value="2">主管</option>
											<option value="99">管理員</option>
										</select>
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="is_super_admin" class="col-sm-2 col-form-label">超級管理員</label>
							<div class="col-sm-10">
								<input v-model="model.is_super_admin"
									type="checkbox"
									class="form-control"
									id="is_super_admin"
									name="is_super_admin"
									value = "1"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="created_at" class="col-sm-2 col-form-label">建立日期</label>
							<div class="col-sm-10">{{ model.created_at }}								
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="updated_at" class="col-sm-2 col-form-label">更新日期</label>
							<div class="col-sm-10">{{ model.updated_at }}								
							</div>
						</div>

					</div>
				</div>
			</form>
		</div>
	</layout-div>
</template>

<script>
	import LayoutDiv from '@/components/LayoutDiv.vue';
	import { getAdmin, postAdmin } from '@/api/Admin.js'

	export default {
		name: 'AdminEdit',
		components: {
			LayoutDiv,
		},
		data() {
			return {
				model: {
					account: '',
					username: '',
					password: '',
					mail: '',
					role: '',
					is_super_admin: '',
					created_at: '',
					updated_at: '',

				},
				isSaving: false,
			};
		},
		created() {
			const id = this.$route.params.id;

			getAdmin(id).then(rep => {
				this.model = rep.data;
			});

		},
		methods: {
			handleSave() {
				if (this.isSaving == true) return;

				this.isSaving = true;
				postAdmin(this.model).then(rep => {

					this.isSaving = false;
					return rep;
				})
					.catch(error => {
						this.isSaving = false;
						return error
					});
			},
		},
	};
</script>